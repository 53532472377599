export default {
  car_types: 'Типы',
  car_marks: 'Марки',
  car_models: 'Модели',
  countries: 'Страны',
  regions: 'Регионы',
  cities: 'Города',
  transmissions: 'Коробки передач',
  fuels: 'Типы топлива',
  drive_unit: 'Тип привода',
  engine_capacities: 'Объемы ДВС',
  engine_powers: 'Мощности ДВС',
  doors: 'Количество дверей',
  colors: 'Цвета',
  car_condition: 'Типы состояний',
  car_securities: 'Типы безопасностей',
  car_multimedia: 'Мультимедиа',
  car_comforts: 'Комфорт',
  car_anothers: 'Другое',
  car_series: 'Типы кузовов',
}
