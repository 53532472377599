<template>
  <div>
    <div class="cui__utils__heading">
      <strong>Справочники: {{ filters[getPath] }}</strong>
    </div>
    <div class="card">
      <div class="card-header card-header-flex">
        <div class="d-flex flex-column justify-content-center mr-auto">
          <h5 class="mb-0">{{ filters[getPath] }}</h5>
        </div>
        <div class="d-flex flex-column justify-content-center mr-2">
          <a-input ref="searchInput" v-model="search" placeholder="Поиск">
            <a-icon slot="prefix" type="search" />
            <a-tooltip slot="suffix" title="Поиск по названию">
              <a-icon type="info-circle" style="color: rgba(0,0,0,.45)" />
            </a-tooltip>
          </a-input>
        </div>
        <div class="d-flex flex-column justify-content-center">
          <a class="btn btn-primary" @click.prevent.stop="$refs.modal.openCreate(list[0])">Добавить</a>
        </div>
      </div>
      <div class="card-body">
        <handbook-table :list="list" @editItem="editItem" @removeItem="removeItem" :types="types" :paginate="pagination" @handleTableChange="handleTableChange" />
      </div>
      <edit-add-type-modal ref="modal" :types="types" :path="getPath" @create="create" @edit="edit" :title="filters[getPath]" />
    </div>
  </div>
</template>

<script>
import apiClient from '@/services/axios'
import EditAddTypeModal from '@/components/HandBook/EditAddTypeModal'
import HandbookTable from '@/components/HandBook/HandbookTable'
import filters from '@/entities/filters'

export default {
  name: 'marks',

  components: {
    EditAddTypeModal,
    HandbookTable,
  },

  data() {
    return {
      list: [],
      filters,
      search: '',
      pagination: {
        current: 1,
        total: 1,
      },
      loading: false,
      types: [],
    }
  },

  created() {
    this.getList()
    this.getTypes()
  },

  watch: {
    '$route.path'() {
      this.pagination.current = 1
      this.getList()
    },
    search() {
      this.pagination.current = null
      this.getList()
    },
  },

  computed: {
    getPath() {
      const path = this.$route.path
      const pathArray = path.split('/')
      return pathArray[pathArray.length - 1]
    },
  },

  methods: {
    async getList(page = this.pagination.current) {
      const params = {
        page: page || null,
        search: this.search ? this.search : null,
      }

      const types = (await apiClient.get(`admin/${this.getPath}`, { params })).data.data.values

      this.list = types.data
      this.pagination = {
        current: types.current_page,
        total: types.last_page * types.per_page,
        pageSize: types.per_page,
      }
    },

    async getTypes() {
      this.types = (await apiClient.get('/admin/car_types')).data.data.values.data
    },

    handleTableChange(pagination) {
      this.pagination.current = pagination.current
      this.getList()
    },

    async removeItem(item) {
      await apiClient.delete(`admin/${this.getPath}/${item.id}`)
      await this.getList()
    },

    editItem(item = null) {
      this.$refs.modal.open(item)
    },

    async create(form, image) {
      const data = Object.assign({}, form)

      if (image) data.image = image

      const response = (await apiClient.post(`/admin/${this.getPath}`, this.getPayload(data))).data
      this.finishFormRequest(response)
    },

    async edit(form, id, image) {
      const data = Object.assign({}, form)

      if (image) {
        data.image = image
      } else if (!image && data.image) {
        delete data.image
      }

      const response = (await apiClient.put(`/admin/${this.getPath}/${id}`, this.getPayload(data))).data
      this.finishFormRequest(response)
    },

    getPayload(form) {
      const payload = Object.assign({}, form)

      if (payload.ru) payload.ru = { name: payload.ru }
      if (payload.ua) payload.ua = { name: payload.ua }

      return payload
    },

    finishFormRequest(response) {
      if (response.status === 'ok') {
        this.$refs.modal.close()
        this.getList()
      }
    },
  },
}
</script>
