export const getBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

export const getFrontUrl = () => {
  return process.env.VUE_APP_FRONT_URL
}

export const formateDate = (date, withTime = true) => {
  if (!date) {
    return null
  }

  const dateFormat = {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  }

  const timeFormat = {
    hour: '2-digit',
    minute: '2-digit',
  }

  const options = {
    ...dateFormat,
    ...(withTime ? timeFormat : {}),
  }

  return new Intl.DateTimeFormat('ru-RU', options).format(new Date(date)).split(',').join(' ')
}

export const activityStatuses = {
  1: { name: 'Активно', id: 1 },
  0: { name: 'Не активно', id: 0 },
}
