<template>
  <a-table :columns="columns" :dataSource="list" :pagination="paginate" @change="$emit('handleTableChange', $event)" :row-key="(record, index) => record.id + index">
    <a
      slot="id"
      slot-scope="text"
      href="javascript: void(0);"
      class="btn btn-sm btn-light"
    >{{text}}</a>
    <span slot="name" slot-scope="record"> {{ record.name || record.value }}</span>
    <span v-if="`${record.position}`" slot="position" slot-scope="record"> {{ record.position }}</span>
    <span slot="status" slot-scope="record"> {{ activityStatuses[record.active].name }}</span>
    <span slot="date" slot-scope="record"> {{ record.updated_at | getFormattedDate }}</span>
    <span v-if="record.car_type_id" slot="type" slot-scope="record"> {{ getCurrentType(record.car_type_id) }}</span>
    <span slot="action" slot-scope="record">
      <a href="javascript: void(0);" class="btn btn-sm btn-light mr-2" @click="$emit('editItem', record)">
        <i class="fe fe-edit mr-2" />
        Изменить
      </a>
      <a-popconfirm
        title="Вы уверенны что хотите удалить?"
        ok-text="Да"
        cancel-text="Нет"
        @confirm="$emit('removeItem', record)"
      >
        <a href="javascript: void(0);" class="btn btn-sm btn-light">
          <small>
            <i class="fe fe-trash mr-2" />
          </small>
          Удалить
        </a>
      </a-popconfirm>
    </span>
  </a-table>
</template>

<script>
import { activityStatuses } from '@/utils'

export default {
  name: 'HandbookTable',

  props: {
    list: {
      type: Array,
      default: () => {
        return {}
      },
    },
    paginate: {
      type: Object,
      default: () => {
        return {}
      },
    },
    types: {
      type: Array,
      default: () => {
        return {}
      },
    },
  },

  data() {
    return {
      activityStatuses,
      columns: [],
    }
  },
  created() {
    this.setColumns()
  },
  methods: {
    getCurrentType(id) {
      const type = this.types.find(item => item.id === id)
      return type ? type.name : ''
    },
    setColumns() {
      const columns = [
        {
          title: 'ID',
          dataIndex: 'id',
          scopedSlots: { customRender: 'id' },
        },
        {
          title: 'Название',
          scopedSlots: { customRender: 'name' },
        },
        {
          title: 'Позиция',
          scopedSlots: { customRender: 'position' },
        },
        {
          title: 'Статус активности',
          scopedSlots: { customRender: 'status' },
        },
        {
          title: 'Последние изменения',
          scopedSlots: { customRender: 'date' },
        },
        {
          title: 'Действия',
          scopedSlots: { customRender: 'action' },
        },
      ]
      if (this.list.length && this.list[0].car_type_id) {
        columns.splice(5, 0, {
          title: 'Тип',
          scopedSlots: { customRender: 'type' },
        })
      }
      if (this.list.length && this.list[0].position === undefined) {
        columns.splice(2, 1)
      }
      this.columns = columns
    },
  },
  watch: {
    list: {
      handler() {
        this.setColumns()
      },
      deep: true,
    },
  },
}
</script>

<style scoped>

</style>
