<template>
  <a-modal v-model="visible" :title="title" :afterClose="close">
    <template slot="footer">
      <a-button key="back" @click="close">
        Отмена
      </a-button>
      <a-button key="submit" type="primary" :loading="loading" @click.prevent.stop="submit">
        {{ !this.isCreate ? 'Изменить' : 'Добавить' }}
      </a-button>
    </template>

    <a-form-model v-if="isLoaded" ref="form" :model="form" :rules="rules">
      <a-form-model-item ref="active" label="Статус" prop="active">
        <a-select v-model="form.active">
          <a-select-option v-for="status in statuses" :key="status.id" :value="status.id">
            {{ status.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item v-if="this.form.position !== undefined" label="Позиция" ref="position" prop="position">
        <a-input v-model="form.position"/>
      </a-form-model-item>
      <a-form-model-item v-if="this.form.ru !== undefined" label="Название(ru)" ref="ru" prop="ru">
        <a-input v-model="form.ru"/>
      </a-form-model-item>
      <a-form-model-item v-if="this.form.ua !== undefined" label="Название(ua)" ref="ua" prop="ua">
        <a-input v-model="form.ua"/>
      </a-form-model-item>
      <a-form-model-item v-if="this.form.value !== undefined" label="Значение" ref="value" prop="value">
        <a-input v-model="form.value"/>
      </a-form-model-item>
      <a-form-model-item v-if="form.car_type_id !== undefined" ref="car_type_id" label="Тип авто" prop="car_type_id">
        <a-select v-model="form.car_type_id" required :rules="rules">
          <a-select-option v-for="type in types" :key="type.id" :value="type.id">
            {{ type.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item v-if="form.car_mark_id !== undefined && isModels" label="Марка" ref="car_mark_id" prop="car_mark_id">
        <a-select
          v-model="form.car_mark_id"
          :default-active-first-option="false"
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="null"
          show-search
          style="width: 100%"
          @search="handleSearch"
          placeholder="выберите марку"
          required
        >
          <a-select-option v-for="item in marks" :key="item.id" :value="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item v-if="form.image !== undefined">
        <a-upload
          list-type="picture-card"
          :file-list="fileList"
          @change="handleChange"
        >
          <div v-if="fileList.length < 1">
            <a-icon type="plus" />
            <div class="ant-upload-text">
              Upload
            </div>
          </div>
        </a-upload>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import apiClient from '@/services/axios'
import { activityStatuses } from '@/utils'
import { getBase64 } from '../../utils'

export default {
  name: 'EditAddTypeModal',

  props: {
    title: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    path: {
      type: String,
      default: '',
    },
    types: {
      type: Array,
      default: () => {
        return {}
      },
    },
  },

  data() {
    return {
      statuses: activityStatuses,
      visible: false,
      search: '',
      isCreate: false,
      isLoaded: false,
      fileList: [],
      marks: [],
      previewVisible: false,
      previewImage: '',
      id: '',
      form: {
        active: '',
        ru: undefined,
        ua: undefined,
        value: undefined,
        image: undefined,
        car_type_id: undefined,
        car_mark_id: undefined,
        position: undefined,
      },
      isValid: false,
      rules: {
        active: [
          {
            required: true,
            message: 'Пожалуйста введите статус',
            trigger: 'blur',
          },
        ],
        car_type_id: [
          {
            required: true,
            message: 'Пожалуйста выберите тип',
            trigger: 'blur',
          },
        ],
        car_mark_id: [
          {
            required: true,
            message: 'Пожалуйста выберите марку',
            trigger: 'blur',
          },
        ],
      },
    }
  },

  computed: {
    isModels() {
      return this.path === 'car_models'
    },
  },

  methods: {
    handleChange({ fileList }) {
      this.fileList = fileList
    },

    handleSearch(value) {
      this.search = value
    },

    async openCreate(item = null) {
      if (item) {
        this.form.active = ''

        if (item.position !== undefined) {
          this.form.position = ''
          this.rules.position = [
            {
              required: true,
              message: 'Пожалуста введите позицию',
              trigger: 'blur',
            },
          ]
        }

        if (item.value) {
          this.rules.value = [
            {
              required: true,
              message: 'Пожалуста введите значение',
              trigger: 'blur',
            },
          ]
          this.form.value = ''
        }

        if (item.car_type_id) {
          this.form.car_type_id = this.types[0]?.id
          this.form.car_mark_id = null
        }

        if (item.icon !== undefined) {
          this.form.image = ''
        }

        if (item.translations) {
          this.rules.ru = [
            {
              required: true,
              message: 'Пожалуста введите название',
              trigger: 'blur',
            },
          ]
          this.rules.ua = [
            {
              required: true,
              message: 'Пожалуста введите название',
              trigger: 'blur',
            },
          ]
          this.form.ru = ''
          this.form.ua = ''
        }
      }

      this.visible = true
      this.isCreate = true
      this.isLoaded = true
    },

    async open(item = null) {
      if (item) {
        this.id = item.id
        this.form.active = item.active

        if (item.position !== undefined) {
          this.form.position = item.position
          this.rules.position = [
            {
              required: true,
              message: 'Пожалуста введите позицию',
              trigger: 'blur',
            },
          ]
        }

        if (item.icon !== undefined) {
          if (item.icon) {
            this.fileList.push({
              url: item.icon,
              uid: '-4',
              name: 'image.png',
              status: 'done',
            })
          }
          this.form.image = item.icon
        }

        if (item.value) {
          this.rules.value = [
            {
              required: true,
              message: 'Пожалуста введите значение',
              trigger: 'blur',
            },
          ]
          this.form.value = item.value
        }

        if (item.car_type_id) {
          this.form.car_type_id = item.car_type_id
        }

        if (item.car_mark_id) {
          this.form.car_mark_id = item.car_mark_id
        }

        if (item.translations) {
          this.rules.ru = [
            {
              required: true,
              message: 'Пожалуста введите название',
              trigger: 'blur',
            },
          ]
          this.rules.ua = [
            {
              required: true,
              message: 'Пожалуста введите название',
              trigger: 'blur',
            },
          ]

          if (item.translations[0].locale === 'ru') {
            this.form.ru = item.translations[0].name
            this.form.ua = item.translations[1].name
          } else {
            this.form.ru = item.translations[1].name
            this.form.ua = item.translations[0].name
          }
        }
      }

      this.visible = true
      this.isLoaded = true
    },

    async getMarks() {
      const params = {
        ...this.search && { search: this.search },
        per_page: 10000,
        car_type: this.form.car_type_id,
      }
      this.marks = (await apiClient.get('/admin/car_marks', { params })).data.data.values.data
    },

    async submit() {
      this.$refs.form.validate(valid => {
        this.isValid = valid
      })

      if (!this.isValid) return
      let image

      if (this.fileList[0] && this.fileList[0].originFileObj) {
        image = await getBase64(this.fileList[0].originFileObj)
      }

      this.isCreate
        ? this.$emit('create', this.form, image)
        : this.$emit('edit', this.form, this.id, image)
    },

    close() {
      this.visible = false
      this.id = ''
      this.search = ''
      this.marks = []
      this.form = {
        active: '',
        ru: undefined,
        ua: undefined,
        value: undefined,
        car_type_id: undefined,
        car_mark_id: undefined,
        position: undefined,
        image: undefined,
      }
      this.fileList = []
      this.isCreate = false
      this.rules = {
        active: [
          {
            required: true,
            message: 'Пожалуста введите статус',
            trigger: 'blur',
          },
        ],
      }
      this.isLoaded = false
    },
  },

  watch: {
    search() {
      this.getMarks()
    },
    'form.car_type_id'(id) {
      id && this.isModels && this.getMarks()
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep .ant-form-item-label {
  height: fit-content;
  line-height: 120%;
  label {
    height: fit-content;
  }
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

::v-deep .ant-form-item-control {
  line-height: 30px;
}
</style>
